.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}
.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}
.portfolio__item-image {
  /* height: auto; */
  border-radius: 1.5rem;
  overflow: hidden;
}
.portfolio__item h3 {
  margin: 1.2rem 0 2rem;
}
.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

header{
    height:100vh;
    padding-top:3rem;
    overflow:hidden;

}
.header__container{
    text-align:center;
    height:100%;
    position: relative;
}

/*===========(CTA)=======*/
.cta{
    margin-top:2rem;
    display:flex;
    gap:1.2rem;
    justify-content: center;

}


/*===========(HeaderSocials)========*/

.header__socials{
    display:flex;
    flex-direction: column;
    align-items:center;
    position:absolute;
    left:0;
    bottom:3rem;
}
.header__socials::after{
    content: "";
    width:2px;
    height:2rem;
    background-color:var(--color-primary);
    
}

/*==========ME==========*/
.me{
    background:linear-gradient(var(--color-primary),transparent);
    width:22rem;
    height:30rem;
    position:absolute;
    left:50%;
    transform: translateX(-11rem);
    margin-top:3rem;
    border-radius:12rem 12rem 0 0;
    overflow:hidden;
    padding:3rem 1.5rem 1.5rem 1.5rem;
}
.me img{
    position:absolute;
    left:1%;
    top:-1%;
}

/* ===============Scroll down==========*/
.scroll__down{
    position:absolute;
    right:-2.3rem;
    bottom:5rem;
    transform: rotate(90deg);
    font-size:0.9rem;
    font-weight: 300;

}

/*=======Media Qweries(Smalldevices)======*/
@media screen and (max-width:1024px) {
    header{
        height: 68vh;

    }
    
}

@media screen and (max-width:600px){
    header{
        height:100vh;
    }
    .header__socials, .scroll__down{
        display:none;
    }
    
}
.portfolio__container{
    display:grid;
    grid-template-columns: repeat(3,1fr);
    gap:2.5rem;
}
/* ==========Media Queries(medium device)========*/
@media screen and (max-width:1024px) {
    .portfolio__container{
        grid-template-columns: 1fr 1fr;
        gap:1.2rem;
    }
}
@media screen and (max-width:600px) {
    .portfolio__container{
        grid-template-columns: 1fr;
        gap:1rem;
    }
}
.container__services{
    display:grid;
    grid-template-columns: repeat(3,1fr);
    gap:3rem;
}
.service{
    background:var(--color-bg-variant);
    border:1px solid var(--color-primary);
    border-radius:0 0 2rem 2rem;
    height:auto;
    transition: var(--transition);

}
.service:hover{
    background:transparent;
    border-color:var(--color-primary-variant);
    cursor:default;
    
}
.service__head{
    background:var(--color-primary);
    padding:2rem;
    border-radius:0 0 2rem 2rem;
    box-shadow:0 2rem 3rem rgba(0,0,0,0.1);

}
.service__head h3{
    text-align:center;
}
.service__list{
    padding:2rem;
}
.service__list li{
    display:flex;
    gap:1rem;
    margin-bottom:0.8rem;
}
.service__list-icon{
    color: var(--color-primary);
    margin-top:3px;
}
.service__list p{
    font-size:0.9rem;
}

/* =============MEDIA QUERIES(MEDIUM DEVICES)========*/
@media screen and (max-width:1024px){
    .container__services{
        grid-template-columns: 1fr 1fr;
        gap:2rem;
        
    }
    .service{
        height:auto;
    }
    
}
/* =============MEDIA QUERIES(SMALL DEVICES)========*/
@media screen and (max-width:600px){
   .container__services{
    grid-template-columns: 1fr;
    gap:1.5rem;
   }
  
}